body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image: url('pd.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App {
  text-align: center;
}

.reservation-form {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 50px auto;
}

.reservation-form h2 {
  margin-bottom: 20px;
}

.reservation-form div {
  margin-bottom: 15px;
}

.reservation-form label {
  display: block;
  margin-bottom: 5px;
}

.reservation-form input,
.reservation-form select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.reservation-form button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reservation-form button:hover {
  background-color: #0056b3;
}
.reservation-form label.cost {
  font-size: 20px; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
  color: #333; /* Change the text color */
  margin-top: 10px; /* Add some space above the label */
}

